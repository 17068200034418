import { default as _simulatorz2SrAEcqlAMeta } from "/vercel/path0/apps/explorer/pages/_simulator.vue?macro=true";
import { default as calculatorsWdRnqFGpBZMeta } from "/vercel/path0/apps/explorer/pages/calculators.vue?macro=true";
import { default as _91cityId_93ZIdHL22LxXMeta } from "/vercel/path0/apps/explorer/pages/contests/dimo/cities/[cityId].vue?macro=true";
import { default as indexmT2WlzLvqtMeta } from "/vercel/path0/apps/explorer/pages/contests/dimo/cities/index.vue?macro=true";
import { default as hex_45selector7ZANJldx3JMeta } from "/vercel/path0/apps/explorer/pages/contests/dimo/hex-selector.vue?macro=true";
import { default as indexoCbUAUCLmiMeta } from "/vercel/path0/apps/explorer/pages/contests/dimo/index.vue?macro=true";
import { default as _91stateId_93QI6zlyTj8yMeta } from "/vercel/path0/apps/explorer/pages/contests/dimo/states/[stateId].vue?macro=true";
import { default as indexZ9iTjmo0mMMeta } from "/vercel/path0/apps/explorer/pages/contests/dimo/states/index.vue?macro=true";
import { default as _91vehicleId_93AreEdwq37vMeta } from "/vercel/path0/apps/explorer/pages/contests/dimo/vehicles/[vehicleId].vue?macro=true";
import { default as dimoKbRHO5TEQZMeta } from "/vercel/path0/apps/explorer/pages/contests/dimo.vue?macro=true";
import { default as indexBskpuI9tY0Meta } from "/vercel/path0/apps/explorer/pages/depins/community/call/index.vue?macro=true";
import { default as _91project_931hMn7ARSf3Meta } from "/vercel/path0/apps/explorer/pages/depins/estimate-rewards/[project].vue?macro=true";
import { default as estimate66iIdyB4uqMeta } from "/vercel/path0/apps/explorer/pages/depins/top-performing-miners/[project]/estimate.vue?macro=true";
import { default as helium_45estimateBJkSsaTuF1Meta } from "/vercel/path0/apps/explorer/pages/depins/top-performing-miners/[project]/helium-estimate.vue?macro=true";
import { default as helium_45successnSbfwsyZNCMeta } from "/vercel/path0/apps/explorer/pages/depins/top-performing-miners/[project]/helium-success.vue?macro=true";
import { default as indexqPA5g69BwyMeta } from "/vercel/path0/apps/explorer/pages/depins/top-performing-miners/[project]/index.vue?macro=true";
import { default as weatherxm_45estimatemDBoNVWvFJMeta } from "/vercel/path0/apps/explorer/pages/depins/top-performing-miners/[project]/weatherxm-estimate.vue?macro=true";
import { default as weatherxm_45success4bZVqa8SXaMeta } from "/vercel/path0/apps/explorer/pages/depins/top-performing-miners/[project]/weatherxm-success.vue?macro=true";
import { default as depinsCpciIHnQDZMeta } from "/vercel/path0/apps/explorer/pages/depins.vue?macro=true";
import { default as _91hexId_934BbQHpId6qMeta } from "/vercel/path0/apps/explorer/pages/hex/[hexId].vue?macro=true";
import { default as activityAEv0LaZpgiMeta } from "/vercel/path0/apps/explorer/pages/hotspots/[cbsdId]/activity.vue?macro=true";
import { default as indexAXETraihCOMeta } from "/vercel/path0/apps/explorer/pages/hotspots/[cbsdId]/index.vue?macro=true";
import { default as iotfmnguxiUPTMeta } from "/vercel/path0/apps/explorer/pages/hotspots/[cbsdId]/iot.vue?macro=true";
import { default as mobileRt4VbFwBb7Meta } from "/vercel/path0/apps/explorer/pages/hotspots/[cbsdId]/mobile.vue?macro=true";
import { default as solanafPozUg0HquMeta } from "/vercel/path0/apps/explorer/pages/hotspots/[cbsdId]/solana.vue?macro=true";
import { default as _91cbsdId_93IjFovLDCdzMeta } from "/vercel/path0/apps/explorer/pages/hotspots/[cbsdId].vue?macro=true";
import { default as indexpTJ7MZ6QCXMeta } from "/vercel/path0/apps/explorer/pages/index.vue?macro=true";
import { default as detaileddwW3mqjkrgMeta } from "/vercel/path0/apps/explorer/pages/network-stats/detailed.vue?macro=true";
import { default as indexPwrqBRn3KiMeta } from "/vercel/path0/apps/explorer/pages/network-stats/index.vue?macro=true";
import { default as leaderboardsMt04G8QAGnMeta } from "/vercel/path0/apps/explorer/pages/network-stats/leaderboards.vue?macro=true";
import { default as wallet_45address_45convertermyJfzoTbI4Meta } from "/vercel/path0/apps/explorer/pages/tools/wallet-address-converter.vue?macro=true";
import { default as walletsBWxvkyG8CkMeta } from "/vercel/path0/apps/explorer/pages/user/wallets.vue?macro=true";
import { default as _91id_93ttgAVCeRL6Meta } from "/vercel/path0/apps/explorer/pages/wallets/[id].vue?macro=true";
import { default as nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.11_@unocss+reset@0.61.5_@upstash+redis@1._x577iqpnw4mru6ihoimo76wn2m/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed } from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.11_@unocss+reset@0.61.5_@upstash+redis@1._x577iqpnw4mru6ihoimo76wn2m/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _simulatorz2SrAEcqlAMeta?.name ?? "_simulator",
    path: _simulatorz2SrAEcqlAMeta?.path ?? "/_simulator",
    meta: _simulatorz2SrAEcqlAMeta || {},
    alias: _simulatorz2SrAEcqlAMeta?.alias || [],
    redirect: _simulatorz2SrAEcqlAMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/_simulator.vue").then(m => m.default || m)
  },
  {
    name: calculatorsWdRnqFGpBZMeta?.name ?? "calculators",
    path: calculatorsWdRnqFGpBZMeta?.path ?? "/calculators",
    meta: calculatorsWdRnqFGpBZMeta || {},
    alias: calculatorsWdRnqFGpBZMeta?.alias || [],
    redirect: calculatorsWdRnqFGpBZMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/calculators.vue").then(m => m.default || m)
  },
  {
    name: dimoKbRHO5TEQZMeta?.name ?? undefined,
    path: dimoKbRHO5TEQZMeta?.path ?? "/contests/dimo",
    meta: dimoKbRHO5TEQZMeta || {},
    alias: dimoKbRHO5TEQZMeta?.alias || [],
    redirect: dimoKbRHO5TEQZMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/contests/dimo.vue").then(m => m.default || m),
    children: [
  {
    name: _91cityId_93ZIdHL22LxXMeta?.name ?? "contests-dimo-cities-cityId",
    path: _91cityId_93ZIdHL22LxXMeta?.path ?? "cities/:cityId()",
    meta: _91cityId_93ZIdHL22LxXMeta || {},
    alias: _91cityId_93ZIdHL22LxXMeta?.alias || [],
    redirect: _91cityId_93ZIdHL22LxXMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/contests/dimo/cities/[cityId].vue").then(m => m.default || m)
  },
  {
    name: indexmT2WlzLvqtMeta?.name ?? "contests-dimo-cities",
    path: indexmT2WlzLvqtMeta?.path ?? "cities",
    meta: indexmT2WlzLvqtMeta || {},
    alias: indexmT2WlzLvqtMeta?.alias || [],
    redirect: indexmT2WlzLvqtMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/contests/dimo/cities/index.vue").then(m => m.default || m)
  },
  {
    name: hex_45selector7ZANJldx3JMeta?.name ?? "contests-dimo-hex-selector",
    path: hex_45selector7ZANJldx3JMeta?.path ?? "hex-selector",
    meta: hex_45selector7ZANJldx3JMeta || {},
    alias: hex_45selector7ZANJldx3JMeta?.alias || [],
    redirect: hex_45selector7ZANJldx3JMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/contests/dimo/hex-selector.vue").then(m => m.default || m)
  },
  {
    name: indexoCbUAUCLmiMeta?.name ?? "contests-dimo",
    path: indexoCbUAUCLmiMeta?.path ?? "",
    meta: indexoCbUAUCLmiMeta || {},
    alias: indexoCbUAUCLmiMeta?.alias || [],
    redirect: indexoCbUAUCLmiMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/contests/dimo/index.vue").then(m => m.default || m)
  },
  {
    name: _91stateId_93QI6zlyTj8yMeta?.name ?? "contests-dimo-states-stateId",
    path: _91stateId_93QI6zlyTj8yMeta?.path ?? "states/:stateId()",
    meta: _91stateId_93QI6zlyTj8yMeta || {},
    alias: _91stateId_93QI6zlyTj8yMeta?.alias || [],
    redirect: _91stateId_93QI6zlyTj8yMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/contests/dimo/states/[stateId].vue").then(m => m.default || m)
  },
  {
    name: indexZ9iTjmo0mMMeta?.name ?? "contests-dimo-states",
    path: indexZ9iTjmo0mMMeta?.path ?? "states",
    meta: indexZ9iTjmo0mMMeta || {},
    alias: indexZ9iTjmo0mMMeta?.alias || [],
    redirect: indexZ9iTjmo0mMMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/contests/dimo/states/index.vue").then(m => m.default || m)
  },
  {
    name: _91vehicleId_93AreEdwq37vMeta?.name ?? "contests-dimo-vehicles-vehicleId",
    path: _91vehicleId_93AreEdwq37vMeta?.path ?? "vehicles/:vehicleId()",
    meta: _91vehicleId_93AreEdwq37vMeta || {},
    alias: _91vehicleId_93AreEdwq37vMeta?.alias || [],
    redirect: _91vehicleId_93AreEdwq37vMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/contests/dimo/vehicles/[vehicleId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: depinsCpciIHnQDZMeta?.name ?? "depins",
    path: depinsCpciIHnQDZMeta?.path ?? "/depins",
    meta: depinsCpciIHnQDZMeta || {},
    alias: depinsCpciIHnQDZMeta?.alias || [],
    redirect: depinsCpciIHnQDZMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/depins.vue").then(m => m.default || m),
    children: [
  {
    name: indexBskpuI9tY0Meta?.name ?? "depins-community-call",
    path: indexBskpuI9tY0Meta?.path ?? "community/call",
    meta: indexBskpuI9tY0Meta || {},
    alias: indexBskpuI9tY0Meta?.alias || [],
    redirect: indexBskpuI9tY0Meta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/depins/community/call/index.vue").then(m => m.default || m)
  },
  {
    name: _91project_931hMn7ARSf3Meta?.name ?? "depins-estimate-rewards-project",
    path: _91project_931hMn7ARSf3Meta?.path ?? "estimate-rewards/:project()",
    meta: _91project_931hMn7ARSf3Meta || {},
    alias: _91project_931hMn7ARSf3Meta?.alias || [],
    redirect: _91project_931hMn7ARSf3Meta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/depins/estimate-rewards/[project].vue").then(m => m.default || m)
  },
  {
    name: estimate66iIdyB4uqMeta?.name ?? "depins-top-performing-miners-project-estimate",
    path: estimate66iIdyB4uqMeta?.path ?? "top-performing-miners/:project()/estimate",
    meta: estimate66iIdyB4uqMeta || {},
    alias: estimate66iIdyB4uqMeta?.alias || [],
    redirect: estimate66iIdyB4uqMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/depins/top-performing-miners/[project]/estimate.vue").then(m => m.default || m)
  },
  {
    name: helium_45estimateBJkSsaTuF1Meta?.name ?? "depins-top-performing-miners-project-helium-estimate",
    path: helium_45estimateBJkSsaTuF1Meta?.path ?? "top-performing-miners/:project()/helium-estimate",
    meta: helium_45estimateBJkSsaTuF1Meta || {},
    alias: helium_45estimateBJkSsaTuF1Meta?.alias || [],
    redirect: helium_45estimateBJkSsaTuF1Meta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/depins/top-performing-miners/[project]/helium-estimate.vue").then(m => m.default || m)
  },
  {
    name: helium_45successnSbfwsyZNCMeta?.name ?? "depins-top-performing-miners-project-helium-success",
    path: helium_45successnSbfwsyZNCMeta?.path ?? "top-performing-miners/:project()/helium-success",
    meta: helium_45successnSbfwsyZNCMeta || {},
    alias: helium_45successnSbfwsyZNCMeta?.alias || [],
    redirect: helium_45successnSbfwsyZNCMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/depins/top-performing-miners/[project]/helium-success.vue").then(m => m.default || m)
  },
  {
    name: indexqPA5g69BwyMeta?.name ?? "depins-top-performing-miners-project",
    path: indexqPA5g69BwyMeta?.path ?? "top-performing-miners/:project()",
    meta: indexqPA5g69BwyMeta || {},
    alias: indexqPA5g69BwyMeta?.alias || [],
    redirect: indexqPA5g69BwyMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/depins/top-performing-miners/[project]/index.vue").then(m => m.default || m)
  },
  {
    name: weatherxm_45estimatemDBoNVWvFJMeta?.name ?? "depins-top-performing-miners-project-weatherxm-estimate",
    path: weatherxm_45estimatemDBoNVWvFJMeta?.path ?? "top-performing-miners/:project()/weatherxm-estimate",
    meta: weatherxm_45estimatemDBoNVWvFJMeta || {},
    alias: weatherxm_45estimatemDBoNVWvFJMeta?.alias || [],
    redirect: weatherxm_45estimatemDBoNVWvFJMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/depins/top-performing-miners/[project]/weatherxm-estimate.vue").then(m => m.default || m)
  },
  {
    name: weatherxm_45success4bZVqa8SXaMeta?.name ?? "depins-top-performing-miners-project-weatherxm-success",
    path: weatherxm_45success4bZVqa8SXaMeta?.path ?? "top-performing-miners/:project()/weatherxm-success",
    meta: weatherxm_45success4bZVqa8SXaMeta || {},
    alias: weatherxm_45success4bZVqa8SXaMeta?.alias || [],
    redirect: weatherxm_45success4bZVqa8SXaMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/depins/top-performing-miners/[project]/weatherxm-success.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91hexId_934BbQHpId6qMeta?.name ?? "hex-hexId",
    path: _91hexId_934BbQHpId6qMeta?.path ?? "/hex/:hexId()",
    meta: _91hexId_934BbQHpId6qMeta || {},
    alias: _91hexId_934BbQHpId6qMeta?.alias || [],
    redirect: _91hexId_934BbQHpId6qMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/hex/[hexId].vue").then(m => m.default || m)
  },
  {
    name: _91cbsdId_93IjFovLDCdzMeta?.name ?? undefined,
    path: _91cbsdId_93IjFovLDCdzMeta?.path ?? "/hotspots/:cbsdId()",
    meta: _91cbsdId_93IjFovLDCdzMeta || {},
    alias: _91cbsdId_93IjFovLDCdzMeta?.alias || [],
    redirect: _91cbsdId_93IjFovLDCdzMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/hotspots/[cbsdId].vue").then(m => m.default || m),
    children: [
  {
    name: activityAEv0LaZpgiMeta?.name ?? "hotspots-cbsdId-activity",
    path: activityAEv0LaZpgiMeta?.path ?? "activity",
    meta: activityAEv0LaZpgiMeta || {},
    alias: activityAEv0LaZpgiMeta?.alias || [],
    redirect: activityAEv0LaZpgiMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/hotspots/[cbsdId]/activity.vue").then(m => m.default || m)
  },
  {
    name: indexAXETraihCOMeta?.name ?? "hotspots-cbsdId",
    path: indexAXETraihCOMeta?.path ?? "",
    meta: indexAXETraihCOMeta || {},
    alias: indexAXETraihCOMeta?.alias || [],
    redirect: indexAXETraihCOMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/hotspots/[cbsdId]/index.vue").then(m => m.default || m)
  },
  {
    name: iotfmnguxiUPTMeta?.name ?? "hotspots-cbsdId-iot",
    path: iotfmnguxiUPTMeta?.path ?? "iot",
    meta: iotfmnguxiUPTMeta || {},
    alias: iotfmnguxiUPTMeta?.alias || [],
    redirect: iotfmnguxiUPTMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/hotspots/[cbsdId]/iot.vue").then(m => m.default || m)
  },
  {
    name: mobileRt4VbFwBb7Meta?.name ?? "hotspots-cbsdId-mobile",
    path: mobileRt4VbFwBb7Meta?.path ?? "mobile",
    meta: mobileRt4VbFwBb7Meta || {},
    alias: mobileRt4VbFwBb7Meta?.alias || [],
    redirect: mobileRt4VbFwBb7Meta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/hotspots/[cbsdId]/mobile.vue").then(m => m.default || m)
  },
  {
    name: solanafPozUg0HquMeta?.name ?? "hotspots-cbsdId-solana",
    path: solanafPozUg0HquMeta?.path ?? "solana",
    meta: solanafPozUg0HquMeta || {},
    alias: solanafPozUg0HquMeta?.alias || [],
    redirect: solanafPozUg0HquMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/hotspots/[cbsdId]/solana.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexpTJ7MZ6QCXMeta?.name ?? "index",
    path: indexpTJ7MZ6QCXMeta?.path ?? "/",
    meta: indexpTJ7MZ6QCXMeta || {},
    alias: indexpTJ7MZ6QCXMeta?.alias || [],
    redirect: indexpTJ7MZ6QCXMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/index.vue").then(m => m.default || m)
  },
  {
    name: detaileddwW3mqjkrgMeta?.name ?? "network-stats-detailed",
    path: detaileddwW3mqjkrgMeta?.path ?? "/network-stats/detailed",
    meta: detaileddwW3mqjkrgMeta || {},
    alias: detaileddwW3mqjkrgMeta?.alias || [],
    redirect: detaileddwW3mqjkrgMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/network-stats/detailed.vue").then(m => m.default || m)
  },
  {
    name: indexPwrqBRn3KiMeta?.name ?? "network-stats",
    path: indexPwrqBRn3KiMeta?.path ?? "/network-stats",
    meta: indexPwrqBRn3KiMeta || {},
    alias: indexPwrqBRn3KiMeta?.alias || [],
    redirect: indexPwrqBRn3KiMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/network-stats/index.vue").then(m => m.default || m)
  },
  {
    name: leaderboardsMt04G8QAGnMeta?.name ?? "network-stats-leaderboards",
    path: leaderboardsMt04G8QAGnMeta?.path ?? "/network-stats/leaderboards",
    meta: leaderboardsMt04G8QAGnMeta || {},
    alias: leaderboardsMt04G8QAGnMeta?.alias || [],
    redirect: leaderboardsMt04G8QAGnMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/network-stats/leaderboards.vue").then(m => m.default || m)
  },
  {
    name: wallet_45address_45convertermyJfzoTbI4Meta?.name ?? "tools-wallet-address-converter",
    path: wallet_45address_45convertermyJfzoTbI4Meta?.path ?? "/tools/wallet-address-converter",
    meta: wallet_45address_45convertermyJfzoTbI4Meta || {},
    alias: wallet_45address_45convertermyJfzoTbI4Meta?.alias || [],
    redirect: wallet_45address_45convertermyJfzoTbI4Meta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/tools/wallet-address-converter.vue").then(m => m.default || m)
  },
  {
    name: walletsBWxvkyG8CkMeta?.name ?? "user-wallets",
    path: walletsBWxvkyG8CkMeta?.path ?? "/user/wallets",
    meta: walletsBWxvkyG8CkMeta || {},
    alias: walletsBWxvkyG8CkMeta?.alias || [],
    redirect: walletsBWxvkyG8CkMeta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/user/wallets.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ttgAVCeRL6Meta?.name ?? "wallets-id",
    path: _91id_93ttgAVCeRL6Meta?.path ?? "/wallets/:id()",
    meta: _91id_93ttgAVCeRL6Meta || {},
    alias: _91id_93ttgAVCeRL6Meta?.alias || [],
    redirect: _91id_93ttgAVCeRL6Meta?.redirect,
    component: () => import("/vercel/path0/apps/explorer/pages/wallets/[id].vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/depins/community-call",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/depins/community-call/:pathMatch(.*)",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/depins/community/call/ *",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/depins/estimator",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/users/:pathMatch(.*)",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/hips/:pathMatch(.*)",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/contests",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/depins/installers",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/depins/dimo",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/depins/dimo/overview",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/depins/network",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/depins/network/geodnet",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/depins/network/weatherxm",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/depins/network/soarchain",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/depins/network/:pathMatch(.*)",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/depins/location-assessmeent/completed",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/location-assessmeent",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/depins/top-performing-miners",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/depins/estimate-rewards",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/terms-conditions",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.path ?? "/privacy-policy",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFedMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_4611__64unocss_43reset_640_4661_465__64upstash_43redis_641uiw86XPFed
  }
]