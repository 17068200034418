import revive_payload_client_iGJuUzOkmh from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.11_@unocss+reset@0.61.5_@upstash+redis@1._x577iqpnw4mru6ihoimo76wn2m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k7R8msYi0G from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.11_@unocss+reset@0.61.5_@upstash+redis@1._x577iqpnw4mru6ihoimo76wn2m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_QwYYNjgnbh from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.11_@unocss+reset@0.61.5_@upstash+redis@1._x577iqpnw4mru6ihoimo76wn2m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import posthog_client_StqKkUjHoV from "/vercel/path0/node_modules/.pnpm/nuxt-posthog@1.5.0_magicast@0.3.4_rollup@4.18.1/node_modules/nuxt-posthog/dist/runtime/plugins/posthog.client.mjs";
import _0_siteConfig_UzlyTFzJkk from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.18.1_vite@5.2.11_@types+node@20.14.11_sass@1._i736bt4a3m774sma6fh34p6oyu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_wuPxAW3vjP from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.11_@unocss+reset@0.61.5_@upstash+redis@1._x577iqpnw4mru6ihoimo76wn2m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_JlAjzhWM5e from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.11_@unocss+reset@0.61.5_@upstash+redis@1._x577iqpnw4mru6ihoimo76wn2m/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_Mjuyvna9aZ from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.1_typescript@5.5.3_vue@3.4.27_typescript@5.5.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/explorer/.nuxt/components.plugin.mjs";
import prefetch_client_p5dS2qAdbu from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.11_@unocss+reset@0.61.5_@upstash+redis@1._x577iqpnw4mru6ihoimo76wn2m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_y7k6KZNBrw from "/vercel/path0/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.4_nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node_kmn5lwy7pzytogsr63hlhc3fuy/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import directives_EvFZFEApwc from "/vercel/path0/node_modules/.pnpm/nuxt-posthog@1.5.0_magicast@0.3.4_rollup@4.18.1/node_modules/nuxt-posthog/dist/runtime/plugins/directives.mjs";
import slideovers_KHcjmmQtcV from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.17.0_axios@1.6.8_focus-trap@7.5.4_idb-keyval@6.2.1_jwt-decode@3.1.2_magicast@0.3.4_gpurknstjj5sn6optda7mknohu/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_MffXV4UloF from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.17.0_axios@1.6.8_focus-trap@7.5.4_idb-keyval@6.2.1_jwt-decode@3.1.2_magicast@0.3.4_gpurknstjj5sn6optda7mknohu/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_CaLd0nYqWH from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.17.0_axios@1.6.8_focus-trap@7.5.4_idb-keyval@6.2.1_jwt-decode@3.1.2_magicast@0.3.4_gpurknstjj5sn6optda7mknohu/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_K4MmN7jGDC from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1_magicast@0.3.4_rollup@4.18.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_ziYcKwnJdq from "/vercel/path0/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import titles_AshzNwdGQS from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.18_h3@1.12.0_magicast@0.3.4_rollup@4.18.1_vite@5.2.11_@types+node@20.14._iqit4jtwzoijpafsyjwjtygeli/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_P3pEopNdmt from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.18.1_vite@5.2.11_@types+node@20.14.11_sass_m7gtnsctuwkieg7asisxcr2b4q/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_xq93scxKIs from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.18.1_vite@5.2.11_@types+node@20.14.11_sass_m7gtnsctuwkieg7asisxcr2b4q/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import scrollbars_client_ZCShyWFEax from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.3.1_axios@1.6.8_focus-trap@7.5.4_idb-keyval@6.2.1_jwt-decode@3.1.2_magicast@0._dgonhy5olirjwb3zz4vlhfqj2e/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import chunk_reload_client_cmltisTKfr from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.11_@unocss+reset@0.61.5_@upstash+redis@1._x577iqpnw4mru6ihoimo76wn2m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import presets_6QOaqxwTOZ from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.3.1_axios@1.6.8_focus-trap@7.5.4_idb-keyval@6.2.1_jwt-decode@3.1.2_magicast@0._dgonhy5olirjwb3zz4vlhfqj2e/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_6dY6ziAThs from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.3.1_axios@1.6.8_focus-trap@7.5.4_idb-keyval@6.2.1_jwt-decode@3.1.2_magicast@0._dgonhy5olirjwb3zz4vlhfqj2e/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import router_scroll_client_cAH5H1OR8X from "/vercel/path0/apps/explorer/plugins/router.scroll.client.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/apps/explorer/plugins/sentry.client.ts";
import trpc_0KeCZOBKLF from "/vercel/path0/apps/explorer/plugins/trpc.ts";
import defaults_CNPKgZbDrr from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.18_h3@1.12.0_magicast@0.3.4_rollup@4.18.1_vite@5.2.11_@types+node@20.14._iqit4jtwzoijpafsyjwjtygeli/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_iGJuUzOkmh,
  unhead_k7R8msYi0G,
  router_QwYYNjgnbh,
  posthog_client_StqKkUjHoV,
  _0_siteConfig_UzlyTFzJkk,
  payload_client_wuPxAW3vjP,
  check_outdated_build_client_JlAjzhWM5e,
  plugin_vue3_Mjuyvna9aZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_p5dS2qAdbu,
  plugin_y7k6KZNBrw,
  directives_EvFZFEApwc,
  slideovers_KHcjmmQtcV,
  modals_MffXV4UloF,
  colors_CaLd0nYqWH,
  plugin_client_K4MmN7jGDC,
  plugin_ziYcKwnJdq,
  titles_AshzNwdGQS,
  siteConfig_P3pEopNdmt,
  inferSeoMetaPlugin_xq93scxKIs,
  scrollbars_client_ZCShyWFEax,
  chunk_reload_client_cmltisTKfr,
  presets_6QOaqxwTOZ,
  variables_6dY6ziAThs,
  router_scroll_client_cAH5H1OR8X,
  sentry_client_shVUlIjFLk,
  trpc_0KeCZOBKLF,
  defaults_CNPKgZbDrr
]